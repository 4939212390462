import React from 'react'
import "./section9.css"
import { CgScreen } from "react-icons/cg";
import { PiTelevisionFill } from "react-icons/pi";
import SportsTv from "../../../Assets/Images/live-sports-bg.jpg"
const index = () => {
  return (
    <div style={{overflowX:"hidden", textAlign:"center"}}>
    <div className='row' >
<div className='col-md-6'>
    <img src={SportsTv} alt="img"  height={500} />
</div>

<div className='col-md-6'>
    <div className='container mt-4'>
      
<h4 className='green_title'>// what you get</h4> 
<h2 className='headingfirstsecthree'>Live Sports and Tv Shows</h2> 
<p className='paragraphLightGray'>Ready to upgrade your TV, Internet, and Phone services? It's as easy as 1-2-3. Contact us today, and our friendly and knowledgeable team will guide you through the process of selecting the perfect plan for your needs.</p>
<h6 style={{marginTop:"-10px"}}>4K UHD Quality</h6>
<p style={{marginTop:"-20px", lineHeight:"4px"}} className='paragraphLightGray'> <CgScreen  size={70} color="#ce1f41"/>    4K, also known as Ultra High Definition (UHD), offers four times the resolution of standard HD, delivering a remarkable level of clarity and detail.</p>
<h6>250+ Channels</h6>
<p style={{marginTop:"-20px", lineHeight:"4px"}}  className='paragraphLightGray'> <PiTelevisionFill   size={70} color="#ce1f41"/>   Experience visuals like never before with the vibrant colors and deep contrast that 4K provides.</p>
</div>
</div>
    </div>
    </div>
  )
}

export default index