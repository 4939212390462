import React from 'react';
import "./section5.css";
import { GiWifiRouter } from "react-icons/gi"; 
import Image from "../../../Assets/Images/save-money-bg-1.jpg";

const Index = ({tfn, getTfnFormat, getTfn, handleClick }) => {
  return (
    <div className="containerother"> {/* Apply the containerother class */}
    
      <img src={Image} alt="Nature" style={{overflow:"hidden", height:"100vh", width: "100%", objectFit: "cover"}}/> {/* Adjust styles for image */}
      <div className='row'>
        <div className='col-md-6'>
           
      <div className="text-block">

        <h3 className='green_title'>// GET EXTRA BENEFIT</h3>
<h4  className='sectionfiveHeadtwo'>Cut costs by combining your internet and TV services into one bundle.</h4>
<p  className='sectionfivepara'>
As our needs for communication and entertainment services grow, bundles offer a solution that delivers both convenience and savings.</p>
   <br/>
   <h4  className='sectionfiveHeadtwo'><GiWifiRouter  size={50} color='#04a096' /> Starting From $ 49.99</h4>
   <p  className='sectionfivepara'>/ month</p>
   <p  className='sectionfiveparaa'>Internet with Tv box</p>
   <div className='btn_div'>
     <a onClick={handleClick}  href={tfn === null ? "tel:+18335256234": getTfn(tfn)} className='thm-btn' style={{ textDecoration:"none", color:"white"}}> Call Now to Order</a>
     </div>
      </div>
      </div>
      <div className='col-md-6'></div>
      </div>
    </div>
  );
}

export default Index;
