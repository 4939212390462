import React from "react";
import "./section7.css";
import { FaCheck } from "react-icons/fa";
import { IoWifi } from "react-icons/io5";
import { PiTelevision } from "react-icons/pi";
import { IoPhonePortraitOutline } from "react-icons/io5";

const index = ({tfn, getTfnFormat, getTfn, handleClick }) => {
  return (
    <div className="sec7bg">
      <div className="container">
        <div className="sec7_heading mb-5">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span>//</span>
            <span className="tariff_span">TARIFFS PLAN</span>
          </div>
          <div>
            <h2 className="subHead_sec7">
              Choose our perfect
              <br /> tariff plan
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div>
              <div className="package_divB">
                <div className="packName_div">
                  <p className="packName_p">Internet</p>
                  <h4 className="packName_h4">Fiber Internet</h4>
                </div>
                <div className="divColbg">
                  <ul className="sec7_ul">
                    <li className="sec7_li">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Up to 200Mbps</div>
                    </li>
                    <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Night Turbo-Speed</div>
                    </li>
                    <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Wifi router</div>
                    </li>

                  </ul>
                  <div className="sec7_pricingDiv">
                    <div className="sec7_p2">
                      <h2 className="sec7_priceH2">
                        <sup className="sup_c">Starting From</sup>
                        <br />
                        $49
                        <sup style={{ top: "-0.3em" }}>.99</sup>
                      </h2>
                      <p className="sec7_priceP">/ month</p>
                    </div>
                  </div>
                  <div className="top_icons">
                    <IoWifi className="icon_wifi"/>
                  </div>
                </div>
              </div>
              <div className="green_btn px-5">
                <a href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"white", textDecoration:"none", pointer:"cursor"}}>Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="package_divB">
                <div className="packName_div">
                  <p className="packName_p">Internet</p>
                  <h4 className="packName_h4">Fiber Internet</h4>
                </div>
                <div className="divColbg">
                  <ul className="sec7_ul">
                    <li className="sec7_li">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Up to 200Mbps</div>
                    </li>
                    <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Night Turbo-Speed</div>
                    </li>
                    <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Wifi router</div>
                    </li>
                    {/* <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Unlimited devices</div>
                    </li> */}
                  </ul>
                  <div className="sec7_pricingDiv">
                    <div className="sec7_p2">
                      <h2 className="sec7_priceH2">
                        <sup className="sup_c">Starting From</sup>
                        <br />
                        $99
                        <sup style={{ top: "-0.3em" }}>.98</sup>
                      </h2>
                      <p className="sec7_priceP">/ month</p>
                    </div>
                  </div>
                  <div className="top_icons">
                    <IoWifi className="icon_wifi mr-1"/>
                    <PiTelevision className="icon_wifi"/>
                  </div>
                </div>
              </div>
              <div className="green_btn px-5">
                <a href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"white", textDecoration:"none", pointer:"cursor"}}>Get Started</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="mb-5">
              <div className="package_divB">
                <div className="packName_div">
                  <p className="packName_p">Internet</p>
                  <h4 className="packName_h4">Fiber Internet</h4>
                </div>
                <div className="divColbg">
                  <ul className="sec7_ul">
                    <li className="sec7_li">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Up to 200Mbps</div>
                    </li>
                    <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Night Turbo-Speed</div>
                    </li>
                    <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Wifi router</div>
                    </li>
                    {/* <li className="sec7_li mt-3">
                      <div>
                        <FaCheck
                          style={{ color: "#08939f", fontSize: "16px" }}
                        />
                      </div>
                      <div className="ml-2 sec7_detail">Unlimited devices</div>
                    </li> */}
                  </ul>
                  <div className="sec7_pricingDiv">
                    <div className="sec7_p2">
                      <h2 className="sec7_priceH2">
                        <sup className="sup_c">Starting From</sup>
                        <br />
                        $114
                        <sup style={{ top: "-0.3em" }}>.97</sup>
                      </h2>
                      <p className="sec7_priceP">/ month</p>
                    </div>
                  </div>
                  <div className="top_icons">
                  <IoWifi className="icon_wifi mr-1"/>
                    <PiTelevision className="icon_wifi mr-1"/>
                    <IoPhonePortraitOutline className="icon_wifi"/>
                  </div>
                </div>
              </div>
              <div className="green_btn px-5 mb-5">
                <a onClick={handleClick} href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"white", textDecoration:"none", pointer:"cursor"}}>Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default index;
