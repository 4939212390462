import React from 'react'
import "./section2.css";
import { GiWifiRouter } from "react-icons/gi"; 
import { PiTelevisionDuotone } from "react-icons/pi";
const index = () => {
  return (
    <div className='container'>
    <div className='row'>
        <div className='col-md-4'>
        <div className='card_div'>
        <div style={{display:"flex", justifyContent:"center"}}>
        <div className='icon_div_white'>
        <GiWifiRouter  size={50} color='#04a096' />
        </div>
        </div>
        <br/>
    <h2>
    We cover up to $500 of your switching cost.
    </h2>

<p>It gives you the service you want without the stress of a long-term contract.</p>
</div>
        </div>
        <div className='col-md-4'>
        <div className='card_div'>
            <div style={{display:"flex", justifyContent:"center"}}>
        <div className='icon_div_white'>
        <PiTelevisionDuotone size={50}   color='#04a096' />
        </div>
        </div>
        <br/>
    <h2>
    No contracts, no hidden fees, and 24/7 support.
    </h2>

<p>Use's patented technology to search providers, compare plans, and order services all on our secure site.</p>
</div>
            </div>
            <div className='col-md-4'>
            <div className='card_div'>
            <div style={{display:"flex", justifyContent:"center"}}>
                <div className='icon_div_white'>
            <GiWifiRouter  size={50} color='#04a096'  />
            </div>
            </div>
        <br/>
    <h2>
    Risk-free 30-day money-back guarantee.
    </h2>

<p>proudly touts 20+ years and 100+ experts dedicated to helping you find what you need.</p>
</div>
            </div>
    </div>
</div>
  )
}

export default index