import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./header.css";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import logo from "../../Assets/Images/logo-03.png"
const Header = () => {
  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-525-6234";
  
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11485112475/w-6VCIid75YZEJvRw-Qq',
        'phone_number': phoneNumber,
      });
  
    } else {
      console.error('Google Analytics gtag function is not defined.');
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);
  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };
  return (
    <header>

      <Navbar
        bg="light"
        data-bs-theme="light"
        fixed="top"
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary top_navbar"
    
      >
        <Container>
          <div className="container" >
          <div className="row" >
            <div className="col-md-3 col-xs-6">
              <Navbar.Brand href="/">
                <div style={{border:"1px solid #828487", borderRadius:"8px", padding:"8px", color:"#04a096"}}>
                  <img src={logo} alt="Linking Flux LLC | Authorized Reseller of TV, Internet & Phone Services"  width={200} />
               {/* Internet Cable Tv <span style={{ color: "#ce1f41" }}>Bundles</span> */}
               </div>
              </Navbar.Brand>
            </div>
            
            <div className="col-md-8 col-xs-6 "  >
              <div className="div_long">
      <div className="hid_mob"  onClick={handleClick}>
            <a onClick={handleClick} href={tfn === null ? "tel:+18335256234": getTfn(tfn)} className="callNowNav" style={{backgroundColor:"#ce1f41", borderRadius:"8px", padding:"8px", display:"flex", textDecoration:"none"}}>
                    <div className="headSetIcon">
                      <TfiHeadphoneAlt className="iconHead"/>
                    </div>
                    <div style={{color:"white", fontSize:"16px"}}>
                      Call Now
                      <br />
                      <span><a  onClick={handleClick}  href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"white", textDecoration:"none"}} > {tfn === null? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn)}</a></span>
                    </div>
                  </a>
            </div>
              <br/>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse id="basic-navbar-nav" className="navbarSecond">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  
                >
                  {/* <Nav.Link href="/">Home</Nav.Link> */}
                  {/* <Nav.Link href="/">About</Nav.Link>
                  <Nav.Link href="/">Partners</Nav.Link>
                  <Nav.Link href="/">Contacts</Nav.Link> */}
                </Nav>

               
                  <Nav.Link href={tfn === null ? "tel:+18335256234": getTfn(tfn)} className="callNowNav" style={{backgroundColor:"#ce1f41", borderRadius:"8px",display:"flex", padding:"8px"}} onClick={handleClick} >
                    <div className="headSetIcon">
                      <TfiHeadphoneAlt className="iconHead"/>
                    </div>
                    <div style={{color:"white", fontSize:"16px"}}>
                      Call Now
                      <br />
                      <span><a onClick={handleClick}  href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"white", textDecoration:"none"}} > {tfn === null? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn)}</a></span>
                    </div>
                  </Nav.Link>
                
              </Navbar.Collapse>
            </div>
            </div>
          </div>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
