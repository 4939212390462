import React from 'react'
import Section1 from "./Container/Section1/index"
const index = () => {
  return (
   <>
   <Section1/>
   </>
  )
}

export default index