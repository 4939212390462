
import React, { useState, useEffect } from "react";
import "./footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTwitter, faFacebookF, faPinterestP, faInstagram } from '@fortawesome/free-brands-svg-icons'; 
const Footer = () => {
  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-525-6234";
  
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11485112475/w-6VCIid75YZEJvRw-Qq',
  
        //  'event_callback': 'callback',
        'phone_number': phoneNumber,
      });
  
    } else {
      console.error('Google Analytics gtag function is not defined.');
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);

  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };
    return (
        <footer>
            <div className="footerContainer">
                <div className="container">
      <div className="row">
<div className="col-md-4"><h3>Internet
Mobile | TV | Phone!</h3>

<div className="social_div">
<div className="site-footer__social">

<FontAwesomeIcon icon={faTwitter} /> </div><div className="site-footer__social">
<FontAwesomeIcon icon={faFacebookF} /></div><div className="site-footer__social">
<FontAwesomeIcon icon={faPinterestP} /></div><div className="site-footer__social">
<FontAwesomeIcon icon={faInstagram} /></div>
</div>
</div>
<div className="col-md-4"><h4>Links</h4>
{/* <p>About</p>
<p>Contacts</p>
<p>Partners</p> */}
<a style={{color:"white", textDecoration:"none"}} href="/privacy-policy">Privacy Policy</a>
</div>
{/* <div className="col-md-3"><h4>Meet us</h4>
<p>23 Gores Dr, Mastic NY 11950</p>
</div> */}
<div className="col-md-4"><h4>Contact</h4>
{/* <p>info@internetcabletvbundles.com</p> */}
<br/>
<a onClick={handleClick}  href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"white", textDecoration:"none"}} className="thm-btnnm" > {tfn === null? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn)}</a>
</div>
<div className="col-md-12">
  <p style={{paddingTop:"5px",fontSize:"14px"}}>Internet + TV Select + Voice: Limited time offer; subject to change; valid to qualified residential customers who have not subscribed to any services within the previous 30 days and who have no outstanding obligation to any provider. TV Select promotion price is $49.99/mo; standard rates apply after yr. 1. Internet promotion price is $49.99/mo; standard rates apply after yr. 1. Voice: Price is $14.99/mo when bundled. Taxes, fees and surcharges (broadcast surcharge up to $21.00/mo) extra and subject to change during and after the promotional period; installation/network activation, equipment and additional services are extra. General Terms: INTERNET: Speed based on wired connection and may vary by address. Wireless speeds may vary. TV: TV equipment required; charges may apply. Channel and HD programming availability based on level of service. Account credentials may be required to stream some TV content online. HOME PHONE: Unlimited calling includes calls within the U.S., Canada, Mexico, Puerto Rico, Guam, the Virgin Islands and more. Services subject to all applicable service terms and conditions, subject to change. Services not available in all areas. Restrictions apply. Enter your address to determine availability.</p>
</div>
      </div>
      </div>
      </div>
      <div className="footerContainerTwo">
        <p>Copyright © 2024 by Internet Cable Tv Bundles, All Rights Reserved.</p>
        {/* <p className="footer_subhead">Linking Flux LLC | Authorized Reseller of TV, Internet & Phone Services</p> */}
      </div>
        </footer>
    );
};

export default Footer;
