import Reacts from "react";
import HomePage from "../Containers/Home/index"
import Privacy from "../Containers/PrivacyPolicy/index"

export const customRoutes = [
    {
        path: "/",
        containers:HomePage 
    },
    {
        path: "/privacy-policy",
        containers: Privacy
        
    },
  
]