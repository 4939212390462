import React, { useState, useEffect } from "react";
import "./style.css"
import Section1 from "./Section1/index"
import Section2 from "./Section2/index"
import Section3 from "./Section3/index"
import Section4 from "./Section4/index"
import Section5 from "./Section5/index"
import Section8 from "./Section8/index"
import Section9 from "./Section9/index"
import Section7 from "./Section7/index"
import Section10 from "./Section10/index"
import MarqueeSection from "./MarqueeSection";
const Index = () => {
  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-525-6234";
  
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11485112475/w-6VCIid75YZEJvRw-Qq',
  
        //  'event_callback': 'callback',
        'phone_number': phoneNumber,
      });
  
    } else {
      console.error('Google Analytics gtag function is not defined.');
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);
  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };

  return (
<>
<Section1 tfn={tfn} handleClick={handleClick}  getTfn={getTfn} getTfnFormat={getTfnFormat}  />
<MarqueeSection/>
<Section2/>


<Section3 tfn={tfn} handleClick={handleClick} getTfn={getTfn} getTfnFormat={getTfnFormat} />
<Section4 />
<Section5 tfn={tfn}  handleClick={handleClick} getTfn={getTfn} getTfnFormat={getTfnFormat} />
<Section7  tfn={tfn} handleClick={handleClick}  getTfn={getTfn} getTfnFormat={getTfnFormat}/>
<Section9/>
<Section8/>

<Section10 tfn={tfn} handleClick={handleClick}  getTfn={getTfn} getTfnFormat={getTfnFormat} />
</>
  )
}

export default Index;
