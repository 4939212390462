import React, { useState, useRef } from "react";

import "./faq.css"
const faqs = [
    {
        id: 1,
        header: "Why should I choose for my internet plan?",
        text: ``
    },
    {
        id: 2,
        header: "What other perks do I get with Internet?",
        text: ``
    },
    {
        id: 3,
        header: "Why should I choose as my cable company?",
        text: ``
    },
    {
        id: 4,
        header: "What are some of TV's programming options?",
        text: ``
    },
    {
        id: 5,
        header: "Why should I choose for my phone plan?",
        text: ``
    },
    {
        id: 6,
        header: "What are some of Voice's more popular features?",
        text: ``
    },
    {
        id: 7,
        header: "What are some of Mobile's more popular features?",
        text: ``
    },
    {
        id: 8,
        header: "What if I want to combine these services?",
        text: ``
    },
    {
        id: 9,
        header: "What's the most popular plan?",
        text: ``
    },
    {
        id: 10,
        header: "What discounts does offer?",
        text: ``
    },
    {
        id: 11,
        header: "How soon can I enjoy services?",
        text: ``
    },
    {
        id: 12,
        header: "What if I'm in another contract for these services?",
        text: ``
    }

]

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{header}</h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    )
}

const Index = () => {
    
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    
    return (
        <>
      
                <div >
                    <div >
                        <div >
                            <div >
                              
                                {faqs.map((faq, index) => {
                                     return (
                                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
    
        </>
    );
};

export default Index
