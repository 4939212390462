import React from "react";
import Header from "../Components/Header/index"; 
import Footer from "../Components/Footer/index"; 
import { withRouter } from "react-router";

const Layout = (props) => {
    return (
        <>
            <Header />
            {props.children}
            <Footer />
        </>
    );
};

export default withRouter(Layout);
