import React from 'react';
import "./section3.css";
import { FaArrowRight } from "react-icons/fa";
import image1 from "../../../Assets/Images/about-1.1.jpg";
import image2 from "../../../Assets/Images/about-1.2.jpg";
const index = ({tfn, getTfnFormat, getTfn, handleClick }) => {
  return (
<div className='container'>
  <div className='sectionthree_main'>
    <div className='row' >
<div className='col-md-6'>
<div className="parent">
  <img className="image1" src={image1} alt="img" />
  <img className="image2" src={image2} alt="img"   />
</div>
</div>
<div className='col-md-6'>
    <div className='div_thirdSection'>
      <h4 className='green_title'>// about us</h4>
    <h2 className='headingfirstsecthree'>
What clients say about us:</h2>
    <br/>
    <h4 className='headingsecondsecthree'>
    “Fast connection, great entertainment.”
    </h4>
    <br/>
    <h5>
    “I signed up for ’s services because it was one of the only options in my area for Internet and cable, but with more options than ever, I have no plans on leaving. TV️, Internet, and phone isn’t breaking my bank, and their customer service reps are always available whenever I have a question.”</h5>
    </div>
    {/* <p className='section3_para'>Michelle SmithStudent</p> */}
    {/* <p className='section3_para'> <div className='about-one__points'><FaArrowRight /></div>Explore comprehensive Internet and TV service offerings.</p>

    <p className='section3_para'> <div className='about-one__points'><FaArrowRight /></div>High-quality services at affordable rates.</p>

    <p className='section3_para'> <div className='about-one__points'><FaArrowRight /></div>Enjoy live sports and TV shows with lightning-fast speeds.</p> */}
    <div className='btn_div'>
     <a onClick={handleClick} href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{ textDecoration:"none", color:"white"}} className='thm-btn'> Call Now</a>
     </div>
    </div>
</div>
</div>
</div>
  )
}

export default index