import React from 'react'
import { IoSettings } from "react-icons/io5";
import { BsSpeedometer } from "react-icons/bs";
import { PiTelevisionFill } from "react-icons/pi";
import { MdSupportAgent } from "react-icons/md";
import { BsBarChartSteps } from "react-icons/bs";
import { CgScreen } from "react-icons/cg";
import "./section4.css"
const index = () => {
  return (
    <div className='dark_Gray_bg'>
   <div className='container'>
    <div className='row' style={{textAlign:"center"}}>
        <div className='col-md-6'>
        <h4 className='green_title'>// our benefits</h4> 
        
        </div>
        <div className='col-md-6'>
            </div>
        <div className='col-md-6'>

        <h2 className='headingfirstsecthree'>Why Choose Us</h2> 
        </div>
        <div className='col-md-6'>
            <p className='paragraphLightGray'>In a myriad of choices, you may question why opt for us. At Internet Cable TV Bundles, we recognize that choosing the perfect partner for your requirements is pivotal. Our differentiator lies in our steadfast commitment to excellence and unwavering dedication to customer satisfaction.</p>
        </div>
    </div>
    <div className='row'>
        <div className='col-md-2'>
<div className='whiteCard'>
    <IoSettings  size={40} color="#04a096"/>
    <h5 className='heading-card'>Installation</h5>
</div>
        </div>
        <div className='col-md-2'>
<div className='whiteCard'>
    <CgScreen  size={40} color="#04a096"/>
    <h5 className='heading-card'>4k Quality</h5>
</div>
        </div>
        <div className='col-md-2'>
<div className='whiteCard'>
    <BsSpeedometer  size={40} color="#04a096"/>
    <h5 className='heading-card'>Ultra Fast</h5>
</div>
        </div>
        <div className='col-md-2'>
<div className='whiteCard'>
    <PiTelevisionFill   size={40} color="#04a096"/>
    <h5 className='heading-card'>Satelite Tv</h5>
</div>
        </div>
        <div className='col-md-2'>
<div className='whiteCard'>
    <BsBarChartSteps  size={40} color="#04a096"/>
    <h5 className='heading-card'>Cheap Plans</h5>
</div>
        </div>
        <div className='col-md-2'>
<div className='whiteCard'>
    <MdSupportAgent   size={40} color="#04a096"/>
    <h5 className='heading-card'>24/7 Support</h5>
</div>
        </div>
    </div>
   </div>
   </div>
  )
}

export default index