import React from "react";
import "./section10.css";


const index = ({tfn, getTfnFormat, getTfn, handleClick }) => {
    
  return (
    <div className="container">
      <div className="sec10 mt-5">
        <div className="sec10_main">
          <div className="blue_bg">
            <div>
              <h3 className="h3_sec10">
                Check ability to connect our <br /> services in your area
              </h3>
            </div>
            <div className="searchBar_div">
              <input type="text" placeholder="Enter address"/>
              <a onClick={handleClick} href={tfn === null ? "tel:+18335256234": getTfn(tfn)} style={{color:"black", textDecoration:"none"}} className="btn_blue">Call Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default index;
