import React from 'react'
import "./setion8.css"
import Faq from "../FAQS/index"
const index = () => {
  return (
    <div className='feedback'>
        <div className='container'>
<div className="row">
    <div className='col-md-6'>
    <h4 className='green_title'>// faqs</h4> 
    <h2 className='headingfirstsecthree'>FAQS</h2>
    <p className='testimonial-one__text'>Do you have a question about ’s services? Are you tired of combing through all the information out there online? Here, you can find the answers to the most frequently asked questions from our loyal customers.</p>
    </div>
    <div className='col-md-6'>
    <Faq/>
    </div>
</div>
        </div>
    </div>
  )
}

export default index