import React from "react";
import "./section1.css";
import Image from "../../../Assets/Images/main-slider-1-1.jpg";
const index = ({tfn, getTfnFormat, getTfn, handleClick }) => {
  return (
    <div className="containerother">
      {/* Apply the containerother class */}
      <div className="image-overlay"></div>
      <img
        src={Image}
        alt="Nature"
        style={{
          overflow: "hidden",
          height: "100vh",
          width: "100%",
          objectFit: "cover",
        }}
      /> 
      {/* <div className="image"></div> */}
      {/* Adjust styles for image */}
      <div className="row">
        <div className="col-md-6">
          <div className="text-block_other">
          <div className="btn_divv">
              <a onClick={handleClick} style={{textDecoration:"none", color:"white"}} href={tfn === null ? "tel:+18335256234": getTfn(tfn)} className="thmm-btn">
              {tfn === null? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn)}</a>
            </div>
            <h3 className="white_title">// TV & INTERNET GREAT PLAN</h3>
            <h4 className="sectiononeHeadtwo">Save Money, Go Faster, No Contracts</h4>
            <p className="sectionfivveparaa">
            Stay connected with all of your devices.
            <br/>
As the fastest-growing internet, TV, home phone, and mobile provider, offers customers the best service at the lowest cost in your area.
<ul>
<li>125+ channels in HD and on demand</li>
<li>Up to 1 Gbps internet speed with no data caps</li>
<li>Affordable home phone with no added fees or dropped calls</li>
</ul>
            </p>
            <br />
            
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>
    </div>
  );
};


export default index;

