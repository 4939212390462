import React from 'react'
import "./section1.css"
const index = () => {
  return (
    <>

    <div className='orange_bg'>
        <h1>Privacy Policy</h1>

    </div>
    <div className='container mt-4'>
    <h4>1. General</h4>
<p>if you are visiting the site from outside the United States, be advised that your personal data will be processed by InternetCableTvBundles.com in the United States, where data protection and privacy standards may differ from similar regulations in other countries. By using the Web site, you consent to any transfer of information outside of your country if you are outside the United States. Furthermore, by using the Website, you agree InternetCableTvBundles.com use your personal information to contact you electronically, in writing or otherwise to provide notices relating to your use of the Website and to provide information about products and services offered by InternetCableTvBundles.com. If you do not wish to receive future promotional materials from us, you can indicate your preference by following the unsubscribe instructions at the end of such communications from us.</p>

<h4>2. Information Gathering / Data processing</h4>
<p>We will never collect sensitive information about you without your explicit consent. When you register to become a client with InternetCableTvBundles.com completing and submitting a form on the website, an account will be created automatically. The registration process will collect your name, address, phone number, email address and information about the location and demographics. When you visit the website InternetCableTvBundles.com, our web servers may use technology to track the patterns of behavior of visitors to our site by collecting number of visits, average time spent, pages viewed, domain name used to access Internet and come to visit website. Furthermore, We use information as mentioned, Your email address, your name and your contact number is just used for communication between you and us, Your IP address is tracked and used for the purpose fraud prevention and your card details and billing information is only disclose to our merchant gateway and your financial institution like bank or card issuer.</p>

<h4>3. Cookies and Log Files</h4>
<p>Cookies may be used on some pages of our site. "Cookies" are small text files placed on your hard drive that helps us provide a more personalized site experience. For example, a cookie can be used to store registration information in an area of the site so that a user does not have to re-enter subsequent visits to that area. Policy is InternetCableTvBundles.com use cookies to facilitate navigation of websites easier for visitors and to facilitate efficient registration procedures.If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our website, however other features on the website may be impaired. After completion of the visit to our site, you can always delete the cookie from your system if desired.In order to properly manage our website may anonymously log information on our operational systems, and identify categories of visitors by items such as domains and browser types. These statistics are presented in the aggregate to our webmasters. This is to ensure that our website presents the best experience for our visitors and is an effective information source.</p>

<h4>4. Your Acceptance of These Terms</h4>
<p>By accessing the Website, you are agreeing to the Privacy Policy InternetCableTvBundles.com. If you do not agree with this Privacy Policy, please do not access the Website. You should visit this page periodically, as we may, from time to time, we will update this Privacy Policy and such changes effective immediately upon posting on the Site without notice to you. Your continued access to the Website following the posting of changes to this Privacy Policy will be considered acceptance of those changes for you. We, however, use personal information only in accordance with the version of this privacy policy under which the information was provided by you.</p>

<h4>6. Security</h4>
<p>We may update this privacy statement in paragraph reflect changes in our information practices. If we make any changes matter if what you know by email (to the email address specified in your account) or by means of a notice on this Site before the change is Effective Right. We encourage you to check this page periodically for updated information on Privacy Practices.</p>

<h4>6. Security</h4>
<p>InternetCableTvBundles.com has implemented generally accepted standards of technology and operational security to protect personally identifiable information from loss, misuse, alteration or destruction. All employees follow security policies across the network. Only authorized personnel access to personally identifiable information and these employees have agreed to ensure confidentiality of this information.</p>

<h4>7. Children</h4>
<p>We understand the importance of protecting children's privacy, especially in an online environment. InternetCableTvBundles.com site covered by this privacy statement are not intentionally designed for or directed at children 13 years old or younger. Policy is not InternetCableTvBundles.com knowingly collect or maintains information from children under 13 years</p>

<h4>8. Limits on Our Abilities</h4>
<p>Although your privacy is very important to us, due to legal and technical environment, we cannot guarantee that your personally identifiable information will not be shared with third parties in ways not described in this policy. Additionally, we can (and you authorize us to) disclose any information about you to private entities, law enforcement or other government officials as we, in our sole discretion, believe necessary or appropriate to address or resolve questions or problems.</p>

<h4>9. Complaints Procedure</h4>
<p>If you have a complaint regarding any aspect of our service, please email us. Please indicate clearly the nature of the complaint. It is also given a contact name that will keep you informed of the progress of any complaint. InternetCableTvBundles.com may reserve the right to change this online privacy policy from time to time at its discretion. Changes will be posted on the website privacy policy and will be effective upon posting. Continued use of InternetCableTvBundles.com after changes in this privacy policy are published online is considered acceptance of those changes. It is your responsibility to oversee privacy policy to determine if such changes have been posted. This privacy policy is subject and all applicable privacy laws.</p>
        </div>

    </>
  )
}

export default index